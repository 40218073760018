@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?82295549');
  src: url('../font/fontello.eot?82295549#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?82295549') format('woff2'),
       url('../font/fontello.woff?82295549') format('woff'),
       url('../font/fontello.ttf?82295549') format('truetype'),
       url('../font/fontello.svg?82295549#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?82295549#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-right-to-rent-default:before { content: '\e800'; } /* '' */
.icon-right-to-rent-fill:before { content: '\e801'; } /* '' */
.icon-copy:before { content: '\e802'; } /* '' */
.icon-branches:before { content: '\e803'; } /* '' */
.icon-close:before { content: '\e804'; } /* '' */
.icon-dfi:before { content: '\e805'; } /* '' */
.icon-favourite:before { content: '\e806'; } /* '' */
.icon-joint-ref:before { content: '\e807'; } /* '' */
.icon-menu:before { content: '\e808'; } /* '' */
.icon-passport:before { content: '\e809'; } /* '' */
.icon-report:before { content: '\e80a'; } /* '' */
.icon-right-to-rent:before { content: '\e80b'; } /* '' */
.icon-settings:before { content: '\e80c'; } /* '' */
.icon-menu-left:before { content: '\e80d'; } /* '' */
.icon-menu-right:before { content: '\e80e'; } /* '' */
.icon-users:before { content: '\e80f'; } /* '' */
.icon-edit:before { content: '\e810'; } /* '' */
.icon-offers:before { content: '\e811'; } /* '' */
.icon-arrow-left:before { content: '\e817'; } /* '' */
.icon-document:before { content: '\e818'; } /* '' */
.icon-arrow-right:before { content: '\e819'; } /* '' */
.icon-question-mark-circle:before { content: '\e81a'; } /* '' */
.icon-download:before { content: '\e81c'; } /* '' */
.icon-email:before { content: '\e81d'; } /* '' */
.icon-attention-default:before { content: '\e81e'; } /* '' */
.icon-attention-fill:before { content: '\e81f'; } /* '' */
.icon-passport-default:before { content: '\e822'; } /* '' */
.icon-passport-fill:before { content: '\e823'; } /* '' */
.icon-plus:before { content: '\e824'; } /* '' */
.icon-profile-add:before { content: '\e825'; } /* '' */
.icon-profile-default:before { content: '\e826'; } /* '' */
.icon-profile-fill:before { content: '\e827'; } /* '' */
.icon-search:before { content: '\e828'; } /* '' */
.icon-progress:before { content: '\e829'; } /* '' */
.icon-swap-vertical:before { content: '\e82a'; } /* '' */
.icon-tick-circle:before { content: '\e82b'; } /* '' */
.icon-tick:before { content: '\e82c'; } /* '' */
.icon-minus:before { content: '\e82d'; } /* '' */
.icon-back:before { content: '\e82e'; } /* '' */
.icon-dfi-default:before { content: '\e82f'; } /* '' */
.icon-dfi-fill:before { content: '\e830'; } /* '' */
.icon-branches-fill:before { content: '\e834'; } /* '' */
.icon-branches-default:before { content: '\e835'; } /* '' */
.icon-dots-horizontal:before { content: '\e836'; } /* '' */
.icon-dots:before { content: '\e837'; } /* '' */
.icon-delete:before { content: '\e838'; } /* '' */
.icon-close-circle-fill:before { content: '\e83a'; } /* '' */
.icon-open:before { content: '\e83b'; } /* '' */
.icon-joint-ref-default:before { content: '\e83c'; } /* '' */
.icon-joint-ref-fill:before { content: '\e83d'; } /* '' */
.icon-phone-fill:before { content: '\e83e'; } /* '' */
.icon-help:before { content: '\e83f'; } /* '' */
.icon-mail-fill:before { content: '\e840'; } /* '' */
.icon-guide:before { content: '\e841'; } /* '' */
.icon-data-not-load-error-default:before { content: '\e842'; } /* '' */
.icon-circle-close:before { content: '\e843'; } /* '' */
.icon-filter-default:before { content: '\e844'; } /* '' */
.icon-filter-fill:before { content: '\e845'; } /* '' */
.icon-down:before { content: '\e846'; } /* '' */
.icon-up:before { content: '\e847'; } /* '' */
.icon-chat-fill:before { content: '\e848'; } /* '' */
.icon-property:before { content: '\e84a'; } /* '' */
.icon-insurance:before { content: '\e84b'; } /* '' */
.icon-settings-default:before { content: '\e857'; } /* '' */
.icon-settings-fill:before { content: '\e858'; } /* '' */
.icon-star_outline:before { content: '\e86e'; } /* '' */
.icon-star_fill:before { content: '\e86f'; } /* '' */
.icon-notification:before { content: '\e889'; } /* '' */
.icon-warning:before { content: '\e88a'; } /* '' */
.icon-arrow_drop_down:before { content: '\e89d'; } /* '' */
.icon-hourglass:before { content: '\e8b3'; } /* '' */
.icon-attention-status:before { content: '\e8e8'; } /* '' */
.icon-loop-status:before { content: '\e8e9'; } /* '' */
.icon-not-started-status:before { content: '\e8ea'; } /* '' */
.icon-accept-status:before { content: '\e8eb'; } /* '' */
.icon-location:before { content: '\e8ec'; } /* '' */
